.retailer-card {
    .badge {
        position: absolute;
        left: 0;
        border-radius: 0;
        font-size: 75%;
        padding: .75rem;
    }

    .card-footer {
        padding: 0;
        border: 0;

        .footer-card {
            background-color: rgba(77,89,149,.06);
        }

        .footer-item {
            flex: 1;
            text-align: center;
            padding: 1.5em 0;
        }
    }
}