// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

@import "./_metronic/_assets/sass/custom.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
  
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.react-bootstrap-table-pagination-list {
  justify-content: flex-end;
  display: flex;
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.table thead th{
  white-space: nowrap;
}

body#kt_body.swal2-height-auto {
    height: 100%!important;
}

.topup-table tbody td:nth-child(5) {
  text-align: right;
}

.summary-table tbody td:nth-child(6){
  text-align: right;
}

.network-group {
  position: relative;
  display: block;
  flex-wrap: wrap;
  vertical-align: middle;

  .btn-check {
      position: absolute;
      clip: rect(0,0,0,0);
      pointer-events: none;
  }

  .btn {
    margin-right: 1rem;
    &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0rem;

    }
  }
  .btn-check:active+.btn-primary, .btn-check:checked+.btn-primary {
    color: #fff;
    background-color: #1bc5bd;
    border-color: #1bc5bd;
  }
  
  .btn.btn-primary.focus:not(.btn-text), 
  .btn.btn-primary:focus:not(.btn-text), 
  .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
      background-color: #1bc5bd !important;
      border-color: #28c9c1 !important;
  }
}



.MuiTable-root {
  min-width: 100% !important;

  .MuiTableCell-root {
    padding: 8px ;
  }
}

/*
@media only screen and (max-width: 700px) {
  .mobile-margin {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1024px) {
  .MuiTable-root {
    min-width: 100% !important;
  
    .MuiTableCell-root {
      padding: 8px ;
    }
  }
} */

@media print { body {transform: scale(.7);} table {page-break-inside: avoid;} }


.small-image {
  width: 50px;
  height: 50px;
}

.__react_modal_image__modal_content .__react_modal_image__medium_img {
  background-color: transparent;
}

.DateRangePicker .DateInput_input {
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateRangePicker .DateRangePickerInput__withBorder {
  border-radius: 0px;
  border: 0px solid #dbdbdb;
}

.DateRangePicker .CalendarMonth_caption {
  font-size: 1.25rem;
}

.DateRangePicker .CalendarDay {
  font-size: 13px;
}

.DateRangePicker .CalendarDay__default {
  border: 0;
}

.DateRangePicker .CalendarDay__blocked_out_of_range, .DateRangePicker .CalendarDay__blocked_out_of_range:active, .DateRangePicker .CalendarDay__blocked_out_of_range:hover {
  border: 0
}